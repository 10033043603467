import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp, Head, Link } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import Vapor from 'laravel-vapor'
import { createPinia } from 'pinia'
import dayjs from './Plugins/daysjs'
import VueLazyLoad from 'vue3-lazyload'

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)
window.Vapor = Vapor

const pinia = createPinia()

createInertiaApp({
    // title: (title) => `${title}`,
    progress: {
        color: '#4B5563',
    },
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        ),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(dayjs)
            .use(VueLazyLoad)
            .component('Link', Link)
            .component('Head', Head)
            .mixin({ methods: { route, asset: window.Vapor.asset } })
            .mount(el)
    },
})
